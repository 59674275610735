import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Presencial de Psicotècnics Aptitudinals per GUB 2024 (10 març)</Title>
                <Text>
                    Et presentem una nova edició del millor curs per preparar els psicotècnics de la
                    convocatòria de la Guàrdia Urbana de Barcelona 2024.
                    <br />
                    <br />
                    Un format intensiu de 8 hores en un dia.
                    <br />
                    <br />
                    El curs s'imparteix el diumenge 10 de març
                    <br />
                    <br />
                    De 9.00 a 13.00 i de 15.00 a 18.00 h
                    <br />
                    <br />
                    Al curs ens centrarem en els conceptes més importants i susceptibles de sortir
                    en l'examen.
                    <br />
                    <br />
                    Amb exercicis basats en anteriors convocatòries i nous exercicis realitzats pel
                    nostre equip de psicòlegs.
                    <br />
                    <br />
                    A iOpos Acadèmia tenim la nostra pròpia metodologia, us ensenyarem trucs propis
                    al curs, us donarem explicacions detallades de cada temàtica i tips per anar
                    molt ràpid.
                    <br />
                    <br />
                    És un dels cursos estrella que tenim a iOpos i estem segurs que un cop fet el
                    curs, canviaràs la teva visió dels psicotècnics.
                    <br />
                    <br />
                    Cada alumne tindrà un dossier específic de franc
                    <br />
                    <br />
                    La formació es realitza al c/Martí Codolar núm 18, Hospitalet de Llobregat
                    <br />
                    <br />
                    T'esperem!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/ybuKQeHb-HI"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
